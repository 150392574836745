class Modal {
  constructor (node, params) {
    if (!node) return
    this.modal = node
    const defaults = {
      overlayId: 'modal-overlay',
      overlayCloseByClick: true,
      overlayZIndex: 100,
      isModal: false,
      closeClass: 'btn_close',
      afterOpen: function () {},
      afterClose: function () {},
      onInit: function () {}
    }
    this.cfg = Object.assign(defaults, params)
    this.isOpen = false
    this.init()
  }

  init () {
    const $this = this
    this.cfg.onInit(this)
    this.overlay = document.getElementById(this.cfg.overlayId)

    if (this.cfg.overlayCloseByClick && !this.cfg.isModal) {
      this.overlay.addEventListener('click', () => $this.close())
      const close = this.modal.getElementsByClassName(this.cfg.closeClass)[0]
      if (close) {
        close.addEventListener('click', () => $this.close())
      }
    }
  }

  show () {
    this.modal.classList.add('open')
    this.overlay.classList.add('visible')
    this.overlay.style.zIndex = this.cfg.overlayZIndex
    this.modal.style.zIndex = this.cfg.overlayZIndex + 1
    this.isOpen = true
    fixBodyWidth(this)
    this.cfg.afterOpen(this)
  }

  close () {
    this.modal.classList.remove('open')
    this.overlay.classList.remove('visible')
    this.overlay.style.zIndex = '-1'
    this.modal.style.zIndex = '-1'
    this.isOpen = false
    fixBodyWidth(this)
    this.cfg.afterClose()
    const event = new Event('modal:after_closed')
    this.overlay.dispatchEvent(event)
  }

  isOpened () {
    return this.isOpen
  }

  setContent (content) {
    const contentWindow = this.modal.getElementsByClassName('modal__content')[0]
    contentWindow.innerHTML = content
  }
}

function fixBodyWidth (context) {
  const header = document.getElementById('header')
  const $this = context
  const bodyWidth = document.body.getBoundingClientRect().width
  const documentWidth = window.innerWidth
  const scrollBarWidth = documentWidth - bodyWidth
  if (!$this.isOpen) {
    document.body.style.overflow = null
    document.body.style.width = null
    header.style.width = '100%'
  } else {
    document.body.style.overflow = 'hidden'
    document.body.style.width = 'calc(100% - ' + scrollBarWidth + 'px)'
    header.style.width = 'calc(100% - ' + scrollBarWidth + 'px)'
  }
}

export default Modal
