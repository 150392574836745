class FormErrors {
  constructor(formNode, errors, params) {
    if (!formNode) return
    this.form = formNode
    if (errors.length > 0) {
      this.errors = JSON.parse(errors).errors
    } else {
      this.errors = {}
    }
    const defaults = {
      formRowClass: 'form-row',
      formRowErrorClass: 'form-error',
      formErrorClass: 'error-msg',
      rowErrorClass: 'has-error',
      beforeParse: () => {}
    }
    this.cfg = Object.assign(defaults, params)
    this.init()
  }

  init() {}

  clear() {
    const formRows = this.form.getElementsByClassName(this.cfg.formRowClass)
    for (let i = 0; i < formRows.length; i++) {
      const row = formRows[i]
      row.classList.remove('.' + this.cfg.formRowErrorClass)
      if (row.getElementsByClassName(this.cfg.formErrorClass)[0]) {
        row.getElementsByClassName(this.cfg.formErrorClass)[0].innerText = ''
      }
    }
  }

  parse() {
    this.cfg.beforeParse(this.form)
    for (const key of Object.keys(this.errors)) {
      const input = document.getElementById(this.form.getAttribute('name') + '_' + key)
      const formRow = input.closest('.' + this.cfg.formRowClass)
      formRow.classList.add('form-error')
      const errorLabel = formRow.getElementsByClassName(this.cfg.formErrorClass)[0]
      errorLabel.innerHTML = this.errors[key][0]
    }
  }
}

export default FormErrors
