const $Ajax = function () {}

$Ajax.prototype.send = function (params) {
  const $this = this
  const defaults = {
    onSend: function () {},
    onOpen: function () {},
    success: function () {},
    error: function () {},
    onResponse: function () {},
    beforeSend: function () {},
    type: 'POST',
    url: null,
    data: null,
    contentType: null
  }
  $this.cfg = Object.assign(defaults, params)
  const xhr = new XMLHttpRequest()
  xhr.open($this.cfg.type, $this.cfg.url, true)
  $this.cfg.onOpen($this)

  $this.cfg.beforeSend()

  xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
  if ($this.cfg.contentType) {
    xhr.setRequestHeader('Content-Type', $this.cfg.contentType)
  }
  if ($this.cfg.data) {
    xhr.send($this.cfg.data)
  } else {
    xhr.send()
  }
  $this.cfg.onSend()
  xhr.onload = function (e) {
    if (xhr.readyState === 4) {
      $this.cfg.onResponse(xhr.responseText, $this)
      if (xhr.status === 200) {
        $this.cfg.success(xhr.responseText, $this)
      } else {
        $this.cfg.error(xhr.status, xhr.response, $this)
      }
    }
  }
}

export default $Ajax
