import Cookies from 'js-cookie'
import $Ajax from 'js/modules/ajax'
import Modal from 'js/modules/modal'
import FormErrors from 'js/modules/formErrors'

class RegisterTemplate {
  constructor (nodeForm, params) {
    if (!nodeForm) return
    const defaults = {
      preloaderNode: document.getElementById('register-modal-preloader'),
      registerSuccessNode: document.getElementById('register-post-modal'),
      modalNode: document.getElementById('register-modal')
    }
    this.cfg = Object.assign(defaults, params)
    this.form = nodeForm
    this.submitBtn = document.getElementById(this.form.getAttribute('name') + '_submit')
    this.context = this

    this.init()
  }

  init () {
    this.createModal()
  }

  send (e) {
    const $this = this
    e.preventDefault()
    const ajax = new $Ajax()
    ajax.send({
      url: this.form.getAttribute('action'),
      type: 'POST',
      data: new FormData(this.form),
      success: $this.onRegisterSuccess.bind($this),
      error: $this.onRegisterError.bind($this),
      onResponse: $this.responseSend.bind(this),
      beforeSend: $this.beforeSend.bind(this)
    })
  }

  createModal () {
    const $this = this
    this.modal = new Modal(this.cfg.modalNode, {
      // afterOpen: _onModalOpened,
      onInit: $this.onModalInit.bind($this),
      overlayId: 'modal-overlay'
    })
    window.$summary.registerModal = this.modal
    if (!window.$summary.auth) return
    const login = window.$summary.auth
    const loginLink = this.modal.modal.getElementsByClassName('modal-auth-link')[0]
    const formSubmitBtn = this.modal.modal.getElementsByTagName('button')[0]
    formSubmitBtn.addEventListener('click', this.submit)
    formSubmitBtn.context = this
    loginLink.addEventListener('click', (e) => {
      e.preventDefault()
      this.modal.close()
      login.show()
    })
  }

  onModalInit () {
    throw 'onModalInit() not implemented'
  }

  submit (e) {
    e.preventDefault()
    const ajax = new $Ajax()
    const $this = e.target.context
    ajax.send({
      url: this.form.getAttribute('action'),
      type: this.form.getAttribute('method'),
      data: new FormData(this.form),
      success: (data) => {
        $this.onRegisterSuccess(data)
      },
      error: (response, errors) => {
        $this.onRegisterError(response, errors)
      },
      beforeSend: (e) => {
        $this.beforeSend(e)
      },
      onResponse: (e) => {
        $this.responseSend(e)
      }
    })
  }

  onRegisterError (response, errors) {
    const errorHandler = new FormErrors(this.form, errors, {
      formRowClass: 'form__group',
      formErrorClass: 'error-msg',
      rowErrorClass: 'has-error'
    })
    errorHandler.clear()
    errorHandler.parse()

    const eventData = { detail: errors }
    document.dispatchEvent(new CustomEvent('register:errors', eventData))
  }

  onRegisterSuccess (data) {
    const $this = this
    ym(56072866, 'reachGoal', 'registrationdone')
    const eventData = { detail: data }
    const event = new CustomEvent('register:afterSuccess', eventData)
    document.dispatchEvent(event)

    const registerSuccess = document.getElementById('register-post-modal')
    const parsedData = JSON.parse(data)
    this.modal.close()
    const registerSuccessModal = new Modal(registerSuccess, {
      afterClose: $this.afterRegisterSuccessModalClose.bind($this)
    })
    registerSuccessModal.show()
  }

  afterRegisterSuccessModalClose () {
    document.location.reload()
  }

  showPreloader () {
    this.cfg.preloaderNode.style.zIndex = 1
    this.cfg.preloaderNode.style.opacity = 1
  }

  hidePreloader () {
    this.cfg.preloaderNode.style.zIndex = -1
    this.cfg.preloaderNode.style.opacity = 0
  }

  responseSend (e) {
    this.hidePreloader()
  }

  beforeSend (e) {
    this.showPreloader()
  }
}

function onSubmitClick (e) {
  e.preventDefault()
  const context = e.target.context
  const form = context.form
  const ajax = new $Ajax()
  ajax.send({
    url: form.getAttribute('action'),
    type: form.getAttribute('method'),
    data: new FormData(form),
    success: onSuccess,
    error: onError,
    beforeSend: beforeSend,
    onResponse: onResponse
  })
}

function onSuccess (data) {

}

function onError () {

}

function beforeSend () {

}

function onResponse () {

}

function _onModalOpened () {
  Cookies.set('client_new', false, { expires: 365 })
}

function _autoOpenModal ({ after = 5000, context = null } = {}) {
  if (!Cookies.get('client_new') && context.modal.isOpened() === false) {
    setTimeout(() => {
      context.modal.show()
      Cookies.set('client_new', false, { expires: 365 })
    }, after)
  }
}

export default RegisterTemplate
