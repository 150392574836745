import RegisterTemplate from 'js/modules/Register/RegisterTemplate'

class Register extends RegisterTemplate {
  constructor (nodeForm, params) {
    super(nodeForm, params)
  }

  onModalInit () {
    const $this = this
  }
}

export default Register
